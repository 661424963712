import { ApiClient } from '@/main/apiClient.ts';
import { PPOBProduct, PPOBMargin } from '@/domain/constant/api_list.ts';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { PPOBMarginModel, PPOBModel } from '@/domain/model/ppob_model.ts';
import PPOBInterface, { PPOBValue } from '@/domain/repository/interface/ppob_interface.ts';

export class PPOBRepository implements PPOBInterface {
  api = new ApiClient();

  async fetchPPOB(data: PPOBValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PPOBProduct.FETCH, {
            params: data
          })
          .then(result => {
            const data = PPOBModel.fromJsonList(result.data.data);

            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async fetchPPOBMargin(data: PPOBValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .get(PPOBMargin.FETCH, {
            params: data
          })
          .then(result => {
            const data = PPOBMarginModel.fromJsonList(result.data.data.result);
            const pagination = PaginationModel.fromJson(result.data.data.pagination);

            const role = {
              data: data,
              pagination: pagination
            };

            resolve(role);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async storePPOBMargin(data: PPOBValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PPOBMargin.STORE, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async updatePPOBMargin(data: PPOBValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .put(PPOBMargin.UPDATE + data.uid, data)
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async deletePPOBMargin(data: PPOBValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .delete(PPOBMargin.DELETE + data.uid, '')
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }

  async importPPOBMargin(data: PPOBValue) {
    try {
      return new Promise((resolve, reject) => {
        this.api
          .post(PPOBMargin.IMPORT, data, {
            contentType: 'multipart/form-data'
          })
          .then(result => {
            resolve(result);
          })
          .catch(err => {
            reject(err);
          });
      });
    } catch (error) {
      return error;
    }
  }
}
