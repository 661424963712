import { useCorporateDetailContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_detail_context.tsx';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import { TabContent, TabPane2, TabPaneList2 } from '@/delivery/components/atoms/tab_pane/tab_pane.tsx';
import DetailCorporate from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/detail/detail_corporate.tsx';
import MyTransaction from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/my_transaction/my_transaction.tsx';
import MyTransactionAffiliate from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/my_transaction_affiliate/my_transaction_affiliate.tsx';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';

const DetailCorporateList = () => {
  const alertToast = useAlertToast();
  const navigate = useNavigate();
  const { uid } = useParams();
  const { corporate, handleShowCorporate, handleShowCorporateVerify } = useCorporateDetailContext();

  useEffect(() => {
    if (uid) {
      handleInitial(uid);
    }
  }, []);

  const handleInitial = async (uid: string) => {
    try {
      const x = alertToast.loadingAlert('');
      await handleShowCorporate({ uid: uid });
      await handleShowCorporateVerify({ user_uid: corporate?.userUID });
      alertToast.updateLoading(x);
    } catch (error: any) {
      alertToast.errorAlert(error);
    }
  };

  const [tabPage, setTabPage] = useState(0);

  const data = [
    {
      label: 'Detail',
      index: 0
      // handleClick: () => {}
    }
    // {
    //   label: 'My Transaction',
    //   index: 1
    //   // handleClick: () => {}
    // },
    // {
    //   label: 'My Transaction Affiliate',
    //   index: 2
    //   // handleClick: () => {}
    // }
  ];

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.DATA_CORPORATE.CORPORATE_LIST)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to corporate list
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          Corporate Detail
        </Typography>
      </div>
      <div className="flex flex-col gap-[20px]">
        <TabPane2>
          {data?.map((item, index) => (
            <TabPaneList2 label={item.label} index={index} tab={tabPage} handleClick={() => setTabPage(index)} />
          ))}
        </TabPane2>

        <TabContent index={0} tab={tabPage}>
          <DetailCorporate />
        </TabContent>
        <TabContent index={1} tab={tabPage}>
          <MyTransaction />
        </TabContent>
        <TabContent index={2} tab={tabPage}>
          <MyTransactionAffiliate />
        </TabContent>
      </div>
    </div>
  );
};

export default DetailCorporateList;
