import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useBusinessVerifyContext } from '@/delivery/ui/admin/data_corporate/business_verify/context/business_verify_context.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';

const TableBankAccount = () => {
  const { businessVerify } = useBusinessVerifyContext();
  return (
    <Table noBorder className="rounded-b-[8px]">
      <Thead>
        <Tr noBorder>
          <Th noBorder>
            <Typography fontWeight={600} size={14} type="primary-invert">
              Akun Bank
            </Typography>
          </Th>
          <Th noBorder></Th>
        </Tr>
      </Thead>
      <Tbody>
        {/*bank name*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Bank Name
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.masterBank?.name}
            </Typography>
          </Td>
        </Tr>

        {/*bank acc no*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Bank Acc No
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.accountNumber}
            </Typography>
          </Td>
        </Tr>

        {/*Bank acc holder*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Bank Acc Holder
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {businessVerify?.accountHolderName}
            </Typography>
          </Td>
        </Tr>

        {/*buku tabungan*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Buku Tabungan
            </Typography>
          </Td>
          <Td noBorder>
            <div className={'flex items-center gap-[20px] pr-[20px]'}>
              <Typography fontWeight={400} size={14} type="primary-invert">
                :
              </Typography>
              <Image url={businessVerify?.getCheckingAccountUrl} />
            </div>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default TableBankAccount;
