import Editor from '@monaco-editor/react';

interface TagEditorProps {
  value?: string;
  onChange?: any;
  height?: string;
}

const TagEditor = (props: TagEditorProps) => {
  const { value, onChange, height } = props;

  return (
    <Editor
      onChange={onChange}
      value={value}
      height={height ? height : '90vh'}
      defaultLanguage={'html'}
      defaultValue={'<div></div>'}
    />
  );
};

export default TagEditor;
