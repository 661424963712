import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useCorporateDetailContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_detail_context.tsx';

const TableBusinessInfo = () => {
  const { corporate } = useCorporateDetailContext();
  return (
    <Table noBorder className="rounded-b-[8px]">
      <Thead>
        <Tr noBorder>
          <Th noBorder>
            <Typography fontWeight={600} size={14} type="primary-invert">
              Informasi Bisnis
            </Typography>
          </Th>
          <Th noBorder></Th>
        </Tr>
      </Thead>
      <Tbody>
        {/*nama usaha*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Nama Usaha
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporate?.name}
            </Typography>
          </Td>
        </Tr>

        {/*email*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Email
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporate?.emailCorporate}
            </Typography>
          </Td>
        </Tr>

        {/*website*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Website
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporate?.website}
            </Typography>
          </Td>
        </Tr>

        {/*phone*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Phone
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporate?.phoneCorporate}
            </Typography>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default TableBusinessInfo;
