import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { useCorporateDetailContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_detail_context.tsx';

const TableMailAddress = () => {
  const { corporate } = useCorporateDetailContext();
  return (
    <Table noBorder className="rounded-b-[8px]">
      <Thead>
        <Tr noBorder>
          <Th noBorder>
            <Typography fontWeight={600} size={14} type="primary-invert">
              Alamat Surat
            </Typography>
          </Th>
          <Th noBorder></Th>
        </Tr>
      </Thead>
      <Tbody>
        {/*mail address*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Alamat
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporate?.mailAddress}
            </Typography>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default TableMailAddress;
