import { MdLogout } from 'react-icons/md';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Image } from '@/delivery/components/atoms/image/image';
import dinaranLogo from '@/delivery/assets/dinaran-logo.png';
import dinaranIcon from '@/delivery/assets/dinaran-favicon.png';

import Menu from '@/delivery/components/atoms/menu/menu';
import { bottomMenu, mainMenu } from '@/domain/constant/side_menu';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import { useAuthContext } from '@/delivery/ui/login/context/auth_context.tsx';
import DialogLogout from '@/delivery/components/organisms/sidebar/dialog_logout.tsx';
// import Navbar from '../navbar/navbar';
import accessMenuCheck from '@/infrastructure/middleware/access_menu';
import DialogAddCustomBaseUrl from '@/delivery/components/organisms/sidebar/dev_mode/dialog_add_custom_base_url.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
// import { getAccess } from '@/infrastructure/helper/auth';

interface SidebarProps {
  children?: ReactNode;
  label?: string;
}

const SidebarPage = (props: SidebarProps) => {
  const { handleAddBaseUrlModal, handleConfirmLogoutModal, handleConfirmLogout } = useAuthContext();
  // const { children, label } = props;
  const { children } = props;
  const [profile, setProfile] = useState<any>();

  const [isSidebarShow, setIsSidebarShow] = useState(true);

  const [clickCount, setClickCount] = useState(0);

  const handleClick = () => {
    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 5) {
        handleAddBaseUrlModal.setState(true);
        return 0; // Reset count after action is triggered
      }
      return newCount;
    });
  };

  useEffect(() => {
    const _profile = getProfile();
    setProfile(_profile);
  }, []);

  useEffect(() => {
    //check display width size
    if (window.innerWidth <= 768) {
      setIsSidebarShow(false);
    }
  }, [window.innerWidth]);

  return (
    <div className={''}>
      <div className={`${isSidebarShow ? 'hidden' : ''} pt-[32px] pl-[32px] xs:pl-[10px]`}>
        <Button iconButton icon={Icon.burger} onClick={() => setIsSidebarShow(true)} />
      </div>
      <div className="flex">
        <p className={'hidden'}>{clickCount}</p>

        <div
          className={`${isSidebarShow ? 'w-72' : 'hidden'}  border-r border-r-border-secondary h-full fixed top-0 left-0 overflow-y-auto bg-content-bg-white`}
        >
          <div className={'flex justify-between items-center'}>
            <div className="px-[24px] pb-[24px] pt-[32px] w-[50%]" onClick={() => handleClick()}>
              <Image zoomClick={false} height={32} url={dinaranLogo} />
            </div>
            <div className={'flex items-center hover:bg-border-tertiary hover:rounded-full mr-[6px]'}>
              <Button iconButton icon={Icon.close} onClick={() => setIsSidebarShow(!isSidebarShow)} />
            </div>
          </div>

          <div className="flex flex-col h-[85vh] justify-between">
            <nav className="flex-grow pt-4  overflow-auto scrollbar-hide px-[16px]">
              {mainMenu.map((item, index) => (
                <div>
                  <Menu
                    key={index}
                    hidden={accessMenuCheck(item.access || []) ? false : true}
                    href={item.href}
                    label={item.label}
                    icon={item.prefixIcon}
                    submenu={item.submenu}
                  />
                </div>
              ))}
            </nav>
            <div className="p-[16px]">
              <div className="pb-[24px]">
                {bottomMenu.map((item, index) => (
                  <Menu
                    key={index}
                    hidden={accessMenuCheck(item.access || []) ? false : true}
                    href={item.href}
                    label={item.label}
                    icon={item.prefixIcon}
                    submenu={item.submenu}
                  />
                ))}
              </div>
              <div className="flex pt-[24px] items-center border-t border-t-border-secondary justify-between">
                <div className="flex gap-[12px]">
                  <Image url={dinaranIcon} className="rounded-full" height={36} width={36} />
                  <div className="flex flex-col">
                    <span className="text-text-secondary-invert font-[500] text-[14px]">{profile?.first_name}</span>
                    <Link to="/" className="text-text-tertiary-invert font-[400] text-[14px]">
                      {profile?.email}
                    </Link>
                  </div>
                </div>
                <span
                  className="text-text-tertiary-invert cursor-pointer flex justify-start items-center hover:bg-border-tertiary hover:rounded-full w-[30px] h-[30px] relative"
                  onClick={() => handleConfirmLogout()}
                >
                  <MdLogout className="w-[36px]" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={` ${isSidebarShow ? 'ml-72' : ''} w-full px-[32px] pb-[32px] flex-grow overflow-y-auto h-screen`}
        >
          {children}
        </div>

        {handleConfirmLogoutModal.state && <DialogLogout modal={handleConfirmLogoutModal} />}

        {handleAddBaseUrlModal.state && <DialogAddCustomBaseUrl modal={handleAddBaseUrlModal} />}
      </div>
    </div>
  );
};

export default SidebarPage;
