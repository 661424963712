import { Image } from '@/delivery/components/atoms/image/image.tsx';
import dinaranLogo from '@/delivery/assets/dinaran-logo.png';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Routing } from '@/domain/constant/router_path.ts';
import { useNavigate } from 'react-router-dom';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useRegistrationCorporateUserContext } from '@/delivery/ui/registration_corporate_user/context/registration_corporate_user_context.tsx';
import { useEffect, useState } from 'react';

const RegistrationCorporateUser = () => {
  const navigate = useNavigate();
  const { handleStoreCorporateUser, formRegistrationCorporateUser, setFormRegistrationCorporateUser } =
    useRegistrationCorporateUserContext();

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const [isPasswordMatched, setIsPasswordMatched] = useState<boolean>(true);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  useEffect(() => {
    if (
      formRegistrationCorporateUser.password !== '' &&
      formRegistrationCorporateUser.first_name !== '' &&
      formRegistrationCorporateUser.last_name !== '' &&
      formRegistrationCorporateUser.email !== '' &&
      formRegistrationCorporateUser.phone !== '' &&
      formRegistrationCorporateUser.username !== ''
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    formRegistrationCorporateUser.first_name,
    formRegistrationCorporateUser.last_name,
    formRegistrationCorporateUser.email,
    formRegistrationCorporateUser.phone,
    formRegistrationCorporateUser.username,
    formRegistrationCorporateUser.password
  ]);

  //CHECK PASSWORD MATCH
  useEffect(() => {
    if (password1 === password2) {
      setIsPasswordMatched(true);
      setFormRegistrationCorporateUser({
        ...formRegistrationCorporateUser,
        password: password1
      });
    } else {
      setIsPasswordMatched(false);
      setFormRegistrationCorporateUser({
        ...formRegistrationCorporateUser,
        password: ''
      });
    }
  }, [password1, password2]);

  return (
    <div className="flex flex-col justify-center items-center h-[100vh] bg-content-bg-secondary">
      <div className="flex items-center flex-col gap-[20px] lg:w-[600px] md:w-[420px] sm:lg:w-full bg-content-bg-white shadow-lg p-[40px] rounded-[20px]">
        <div className="mb-[40px]">
          <Image width={150} url={dinaranLogo} />
        </div>
        <div className={'w-full flex-col flex gap-[10px]'}>
          {/*<div className={'flex gap-[20px]'}>*/}
          {/*  <TextField*/}
          {/*    block*/}
          {/*    label={'Nama Depan'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormRegistrationCorporateUser({*/}
          {/*        ...formRegistrationCorporateUser,*/}
          {/*        first_name: e.target.value*/}
          {/*      })*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <TextField*/}
          {/*    block*/}
          {/*    label={'Nama Belakang'}*/}
          {/*    onChange={e =>*/}
          {/*      setFormRegistrationCorporateUser({*/}
          {/*        ...formRegistrationCorporateUser,*/}
          {/*        last_name: e.target.value*/}
          {/*      })*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}

          <TextField
            block
            label={'Nama Usaha'}
            onChange={e =>
              setFormRegistrationCorporateUser({
                ...formRegistrationCorporateUser,
                company_name: e.target.value
              })
            }
          />
          <TextField
            block
            label={'Email'}
            onChange={e =>
              setFormRegistrationCorporateUser({
                ...formRegistrationCorporateUser,
                email: e.target.value
              })
            }
          />
          <TextField
            block
            label={'Phone'}
            onChange={e =>
              setFormRegistrationCorporateUser({
                ...formRegistrationCorporateUser,
                phone: e.target.value
              })
            }
          />
          {/*<TextField*/}
          {/*  block*/}
          {/*  label={'Username'}*/}
          {/*  onChange={e =>*/}
          {/*    setFormRegistrationCorporateUser({*/}
          {/*      ...formRegistrationCorporateUser,*/}
          {/*      username: e.target.value*/}
          {/*    })*/}
          {/*  }*/}
          {/*/>*/}
          <div className={'flex gap-[20px]'}>
            <TextField
              type={'password'}
              block
              label={'Password'}
              onChange={e => {
                setPassword1(e.target.value);
              }}
            />
            <TextField
              block
              label={'Ulangi Password'}
              type={'password'}
              onChange={e => {
                setPassword2(e.target.value);
              }}
            />
          </div>
          <div>
            <p className={'text-[12px] italic'}>
              password harus mengandung angka, huruf kapital dan karakter kusus, contoh: @co#Ntoh23
            </p>
          </div>
          <div className={'h-[20px]'}>
            {!isPasswordMatched && (
              <Typography size={14} className={'text-text-danger font-[700] italic'}>
                password tidak sama
              </Typography>
            )}
          </div>
          <div className={'mt-[10px]'}>
            <Button disabled={buttonDisabled} block label={'Register'} onClick={() => handleStoreCorporateUser()} />
          </div>
        </div>
      </div>
      <div className={'mt-[20px]'}>
        <Typography tag={'p'} size={14} fontWeight={500} type={'tertiary-invert'}>
          Sudah punya akun bisnis ?{' '}
          <span className={'text-bg-primary cursor-pointer'} onClick={() => navigate(Routing.LOGIN)}>
            Login
          </span>
        </Typography>
      </div>
    </div>
  );
};

export default RegistrationCorporateUser;
