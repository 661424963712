import React, { createContext, useContext, ReactNode, Dispatch, SetStateAction, useState } from 'react';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { ModalObject } from '@/delivery/interface/modal_interface.ts';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent.tsx';
import { RegionProvinceModel } from '@/domain/model/region_province_model.ts';
import { RegionDistrictModel } from '@/domain/model/region_district_model.ts';
import { RegionVillageModel } from '@/domain/model/region_village_model.ts';
import { RegionCityModel } from '@/domain/model/region_city_model.ts';
import { RegionRepository } from '@/domain/repository/region_repository.ts';
import { MasterBankRepository } from '@/domain/repository/master_bank_repository.ts';
import { MasterBankModel } from '@/domain/model/master_bank_model.ts';
import UploadMedia from '@/infrastructure/helper/uploadMedia.tsx';
import MEDIA_PATH from '@/domain/constant/media/media_path.ts';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import { NATIONAL_ID } from '@/domain/constant/national_id.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { CorporateVerifyRepository } from '@/domain/repository/corporate_verify_repository.ts';
import { AffiliateRepository } from '@/domain/repository/affiliate_repository.ts';
import { AffiliateModel } from '@/domain/model/affiliate_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateProfileValueParams {
  page?: number;
  limit?: number;
  email?: string;
  user_uid?: string;
  full_name?: string;
}

interface FormCreateCorporate {
  uid?: string;
  user_uid?: string;
  pic_name?: string;
  identity_media?: string;

  name?: string;
  phone?: string;
  email?: string;
  website?: string;
  checking_account_media?: string;
  company_deed_media?: string;
  tax_payer_identification_number_media?: string;
  company_registration_number_media?: string;

  checking_account_media_uuid?: string;
  company_deed_media_uuid?: string;
  tax_payer_identification_number_media_uuid?: string;
  company_registration_number_media_uuid?: string;

  master_bank_uuid?: string;
  account_number?: string;
  account_holder_name?: string;

  province?: string;
  city?: string;
  district?: string;
  village?: string;
  full_address?: string;
  national_id?: string;
  province_id?: string;
  city_id?: string;
  district_id?: string;
  village_id?: string;
  zip_code?: string;
  mail_address?: string;
  mail_address_first?: string;
}

interface CorporateProfileValue {
  //INTERFACE
  profileAffiliate?: AffiliateModel;
  corporate?: CorporateModel;
  corporateVerify?: CorporateModel;
  mailAddressCopyBusinessAddress: boolean;
  masterBanks: MasterBankModel[];
  handleShowCorporate: (_data: CorporateProfileValueParams) => Promise<any>;
  handleShowStoreCorporateModal: ModalObject;
  formCreateCorporate: FormCreateCorporate;
  setFormCreateCorporate: Dispatch<SetStateAction<FormCreateCorporate>>;
  handleFetchMasterBank: () => void;
  handleMailAddressCopyBusinessAddress: () => void;
  handleStoreCorporate: (_data: CorporateProfileValueParams) => void;
  handleShowCorporateVerification: (_data: CorporateProfileValueParams) => Promise<any>;
  handleShowProfileAffiliate: (_data: CorporateProfileValueParams) => Promise<any>;

  provinces: RegionProvinceModel[];
  cities: RegionDistrictModel[];
  districts: RegionDistrictModel[];
  villages: RegionVillageModel[];
  handleFetchProvince: () => void;
  handleFetchCities: () => void;
  handleFetchDistricts: () => void;
  handleFetchVillage: () => void;

  provinceID: number;
  setProvinceID: Dispatch<SetStateAction<number>>;
  cityID: number;
  setCityID: Dispatch<SetStateAction<number>>;
  districtID: number;
  setDistrictID: Dispatch<SetStateAction<number>>;
  villageID: number;
  setVillageID: Dispatch<SetStateAction<number>>;
}

const CorporateProfileContext = createContext<CorporateProfileValue | null>(null);

//REPOSITORY HERE
const corporateRep = new CorporateRepository();
const regionRepository = new RegionRepository();
const masterBankRep = new MasterBankRepository();
const corporateVerifyRep = new CorporateVerifyRepository();
const affiliateProfileRep = new AffiliateRepository();

const CorporateProfileProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  //const alertSweet = useAlertSweetComponent();

  const handleShowStoreCorporateModal = useVisibleComponent(false);
  const [formCreateCorporate, setFormCreateCorporate] = useState<FormCreateCorporate>({});
  const [masterBanks, setMasterBanks] = useState<MasterBankModel[]>([]);
  const [mailAddressCopyBusinessAddress, setMailAddressCopyBusinessAddress] = useState<boolean>(false);

  const [corporate, setCorporate] = useState<CorporateModel>();
  const [corporateVerify, setCorporateVerify] = useState<CorporateModel>();
  const [profileAffiliate, setProfileAffiliate] = useState<AffiliateModel>();

  const [provinces, setProvinces] = useState<RegionProvinceModel[]>([]);
  const [cities, setCities] = useState<RegionCityModel[]>([]);
  const [districts, setDistricts] = useState<RegionDistrictModel[]>([]);
  const [villages, setVillages] = useState<RegionVillageModel[]>([]);

  const [provinceID, setProvinceID] = useState<number>(0);
  const [cityID, setCityID] = useState<number>(0);
  const [districtID, setDistrictID] = useState<number>(0);
  const [villageID, setVillageID] = useState<number>(0);
  //FUNCTION HERE BELOW

  //SHOW CORPORATE
  const handleShowCorporate = async (_data: CorporateProfileValueParams) => {
    const params = {
      // email: _data.email,
      user_uid: _data.user_uid
    };

    return await corporateRep
      .showCorporate(params)
      .then((result: any) => {
        setCorporate(result);

        // const corporateData: CorporateModel = result;
        // setFormCreateCorporate({
        //   uid: corporateData?.UID,
        //   name: corporateData.name,
        //   email: corporateData.emailCorporate,
        //   phone: corporateData.phoneCorporate,
        //   website: corporateData.website,
        //   master_bank_uuid: corporate?.masterBank.UUID,
        //   account_number: corporate?.accountNumber,
        //   account_holder_name: corporate?.accountHolderName
        // });
      })
      .catch(error => {
        // alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW CORPORATE VERIFICATION
  const handleShowCorporateVerification = async (_data: CorporateProfileValueParams) => {
    const params = {
      // email: _data.email,
      user_uid: _data.user_uid
    };

    return await corporateVerifyRep
      .showCorporateVerify(params)
      .then((result: any) => {
        setCorporateVerify(result);

        const corporateData: CorporateModel = result;
        setFormCreateCorporate({
          uid: corporateData?.corporateUID,
          name: corporateData.name,
          email: corporateData.emailCorporate,
          phone: corporateData.phoneCorporate,
          website: corporateData.website,
          master_bank_uuid: corporateData?.masterBank.UID,
          account_number: corporateData?.accountNumber,
          account_holder_name: corporateData?.accountHolderName,
          full_address: corporateData?.fullAddress,
          zip_code: corporateData?.zipCode,
          mail_address: corporateData?.mailAddress,
          pic_name: corporateData?.picName,

          checking_account_media_uuid: corporateData?.checkingAccountMediaUID,
          company_deed_media_uuid: corporateData?.companyDeedMediaUID,
          company_registration_number_media_uuid: corporateData?.companyRegistrationNumberMediaUID,
          tax_payer_identification_number_media_uuid: corporateData?.taxpayerIdentificationNumberMediaUID
        });

        setProvinceID(corporateData?.provinceID);
        setCityID(corporateData?.cityID);
        setDistrictID(corporateData?.districtID);
        setVillageID(corporateData?.villageID);
      })
      .catch(error => {
        // alertToast.errorAlert(error);
      });
  };

  //HANDLE SHOW PROFILE AFFILIATE
  const handleShowProfileAffiliate = async (_data: CorporateProfileValueParams) => {
    const params = {
      email: _data.email
    };

    return await affiliateProfileRep
      .showProfileAffiliate(params)
      .then((result: any) => {
        setProfileAffiliate(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH MASTER BANK
  const handleFetchMasterBank = () => {
    masterBankRep
      .fetchMasterBank({ limit: -1 })
      .then((res: any) => {
        const result: MasterBankModel[] = res.data;
        setMasterBanks(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE MAIL ADDRESS COPY BUSINESS ADDRESS
  const handleMailAddressCopyBusinessAddress = () => {
    setMailAddressCopyBusinessAddress(!mailAddressCopyBusinessAddress);

    const copyMailAddress = `${formCreateCorporate.village ? ` ${formCreateCorporate.village},` : ''} ${formCreateCorporate.district ? ` ${formCreateCorporate.district},` : ''} ${formCreateCorporate.city ? ` ${formCreateCorporate.city},` : ''} ${formCreateCorporate.province ? ` ${formCreateCorporate.province},` : ''} ${formCreateCorporate.full_address ? ` ${formCreateCorporate.full_address},` : ''} ${formCreateCorporate.zip_code ? ` ${formCreateCorporate.zip_code}` : ''}`;
    const mailAddress = formCreateCorporate.mail_address_first;

    if (!mailAddressCopyBusinessAddress) {
      setFormCreateCorporate({ ...formCreateCorporate, mail_address: copyMailAddress });
    } else {
      setFormCreateCorporate({ ...formCreateCorporate, mail_address: mailAddress });
    }
  };

  //HANDLE STORE CORPORATE
  const handleStoreCorporate = async (_data: CorporateProfileValueParams) => {
    if (
      formCreateCorporate.name ||
      formCreateCorporate.website ||
      formCreateCorporate.company_registration_number_media ||
      formCreateCorporate.full_address ||
      formCreateCorporate.zip_code
    ) {
      const x = alertToast.loadingAlert('');

      if (!mailAddressCopyBusinessAddress) {
        handleMailAddressCopyBusinessAddress();
      }

      let identityMediaUID = '';
      if (formCreateCorporate.identity_media) {
        identityMediaUID = await UploadMedia(formCreateCorporate.identity_media, MEDIA_PATH.IDENTITY);
      } else {
        identityMediaUID = '';
      }

      let companyRegistrationNumberMediaUID = '';
      if (formCreateCorporate.company_registration_number_media) {
        companyRegistrationNumberMediaUID = await UploadMedia(
          formCreateCorporate.company_registration_number_media,
          MEDIA_PATH.COMPANY_REGISTRATION_NUMBER
        );
      } else {
        companyRegistrationNumberMediaUID = formCreateCorporate.company_registration_number_media_uuid ?? '';
      }

      let companyDeedMediaUID = '';
      if (formCreateCorporate.company_deed_media) {
        companyDeedMediaUID = await UploadMedia(formCreateCorporate.company_deed_media, MEDIA_PATH.COMPANY_DEED);
      } else {
        companyDeedMediaUID = formCreateCorporate.company_deed_media_uuid ?? '';
      }

      let taxPayerIdentificationNumberMediaUID = '';
      if (formCreateCorporate.tax_payer_identification_number_media) {
        taxPayerIdentificationNumberMediaUID = await UploadMedia(
          formCreateCorporate.tax_payer_identification_number_media,
          MEDIA_PATH.TAX_PAYER_IDENTIFICATION_NUMBER
        );
      } else {
        taxPayerIdentificationNumberMediaUID = formCreateCorporate.tax_payer_identification_number_media_uuid ?? '';
      }

      let checkingAccountMediaUID = '';
      if (formCreateCorporate.checking_account_media) {
        checkingAccountMediaUID = await UploadMedia(
          formCreateCorporate.checking_account_media,
          MEDIA_PATH.CHECKING_ACCOUNT
        );
      } else {
        checkingAccountMediaUID = formCreateCorporate.checking_account_media_uuid ?? '';
      }

      const paramsCorporate = {
        uid: formCreateCorporate.uid,
        user_uid: _data?.user_uid,
        name: formCreateCorporate.name,
        email: formCreateCorporate.email,
        phone: formCreateCorporate.phone,
        // reg_date: formatDateParam(new Date()),
        pic_name: formCreateCorporate.pic_name,
        permit: '',
        website: formCreateCorporate.website,
        company_registration_number_media_uid: companyRegistrationNumberMediaUID,
        company_deed_media_uid: companyDeedMediaUID,
        tax_payer_identification_number_media_uid: taxPayerIdentificationNumberMediaUID,
        checking_account_media_uid: checkingAccountMediaUID,
        identity_media_uid: identityMediaUID,
        mail_address: formCreateCorporate.mail_address,
        notes: '',
        status: STATUS_CORPORATE.REQUEST,
        latitude: 0,
        longitude: 0,
        full_address: formCreateCorporate.full_address,
        national_id: NATIONAL_ID,
        province_id: provinceID,
        city_id: cityID,
        district_id: districtID,
        village_id: villageID,
        zip_code: formCreateCorporate.zip_code
      };

      corporateRep
        .updateCorporate(paramsCorporate)
        .then(() => {
          const paramsCorporateVerify = {
            flags: [],
            corporate_uid: formCreateCorporate.uid,
            status: STATUS_CORPORATE.REQUEST,
            name: formCreateCorporate.name,
            email: formCreateCorporate.email,
            phone: formCreateCorporate.phone,
            // reg_date: formatDateParam(new Date()),
            pic_name: formCreateCorporate.pic_name, //user?.getFullName,
            // permit: '',
            website: formCreateCorporate.website,
            company_registration_number_media_uid: companyRegistrationNumberMediaUID,
            company_deed_media_uid: companyDeedMediaUID,
            tax_payer_identification_number_media_uid: taxPayerIdentificationNumberMediaUID,
            checking_account_media_uid: checkingAccountMediaUID,
            identity_media_uid: identityMediaUID,
            mail_address: formCreateCorporate.mail_address,
            master_bank_uuid: formCreateCorporate.master_bank_uuid,
            type: '',
            account_number: formCreateCorporate.account_number,
            account_holder_name: formCreateCorporate.account_holder_name,
            notes: '',
            media_uid: '',
            latitude: 0,
            longitude: 0,
            full_address: formCreateCorporate.full_address,
            national_id: NATIONAL_ID,
            province_id: provinceID,
            city_id: cityID,
            district_id: districtID,
            village_id: villageID,
            zip_code: formCreateCorporate.zip_code
          };

          corporateVerifyRep
            .storeCorporateVerify(paramsCorporateVerify)
            .then(() => {
              alertToast.updateLoading(x);
              alertToast.successAlert();
              handleShowStoreCorporateModal.setState(false);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch(error => {
              alertToast.updateLoading(x);
              alertToast.errorAlert(error);
            });
        })
        .catch(error => {
          alertToast.updateLoading(x);
          alertToast.errorAlert(error);
        });
    } else {
      alertToast.warningAlertValidation();
    }
  };

  // ------ LOCATION ADDRESS BELOW ------ //

  //HANDLE FETCH PROVINCE
  const handleFetchProvince = () => {
    regionRepository
      .fetchProvince()
      .then((result: any) => {
        setProvinces(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH CITIES
  const handleFetchCities = () => {
    const params = {
      parent_id: provinceID
    };
    regionRepository
      .fetchCities(params)
      .then((result: any) => {
        setCities(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH DISTRICT
  const handleFetchDistricts = () => {
    const params = {
      parent_id: cityID
    };
    regionRepository
      .fetchDistrict(params)
      .then((result: any) => {
        setDistricts(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //HANDLE FETCH VILLAGE
  const handleFetchVillage = () => {
    const params = {
      parent_id: districtID
    };

    regionRepository
      .fetchVillage(params)
      .then((result: any) => {
        setVillages(result.data);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateProfileValue = {
    //INTERFACE
    profileAffiliate,
    corporate,
    corporateVerify,
    masterBanks,
    handleShowCorporate,
    handleShowStoreCorporateModal,
    formCreateCorporate,
    setFormCreateCorporate,
    handleFetchMasterBank,
    handleMailAddressCopyBusinessAddress,
    handleStoreCorporate,
    mailAddressCopyBusinessAddress,
    handleShowCorporateVerification,
    handleShowProfileAffiliate,

    provinces,
    cities,
    districts,
    villages,
    handleFetchProvince,
    handleFetchCities,
    handleFetchDistricts,
    handleFetchVillage,

    provinceID,
    setProvinceID,
    cityID,
    setCityID,
    districtID,
    setDistrictID,
    villageID,
    setVillageID
  };

  return <CorporateProfileContext.Provider value={contextValue}>{children}</CorporateProfileContext.Provider>;
};

const useCorporateProfileContext = (): CorporateProfileValue => {
  const context = useContext(CorporateProfileContext);
  if (!context) {
    throw new Error('useCorporateProfileContext must be used within a CorporateProfileProvider');
  }
  return context;
};

export { CorporateProfileProvider, useCorporateProfileContext };
