import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Dropzone from '@/delivery/components/atoms/dropzone/dropzone.tsx';
import { useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { usePPOBContext } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';
import { useParams } from 'react-router-dom';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

const DialogImportMarginPPOB = (props: ModalDialogProps) => {
  const { vendor_uid, ppob_product_code } = useParams();

  const { handleImportPPOBMargin } = usePPOBContext();

  const [fileBeriEmas, setFileBeriEmas] = useState<any>();

  return (
    <ModalDialog overflow={false} size="small" title="Import Margin" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <div>
          <Typography fontWeight={400} size={14} type={'tertiary-invert'} tag={'span'}>
            Download template{' '}
            <a
              download="sample_file_import_margin_ppob_din.xlsx"
              href={'/sample_file_import_margin_ppob_din.xlsx'}
              className={'cursor-pointer underline text-text-secondary-invert'}
            >
              excel
            </a>
          </Typography>
        </div>
        <Dropzone fileTypes={['.xls, .xlsx']} id={'notify-app'} onChange={e => setFileBeriEmas(e)} />
      </div>
      <ModalDialogFooter className="flex justify-end gap-[20px]">
        <Button
          label={'Import'}
          onClick={() =>
            handleImportPPOBMargin({
              vendor_uid: vendor_uid,
              file: fileBeriEmas,
              preview: false,
              ppob_product_code: ppob_product_code
            })
          }
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogImportMarginPPOB;
