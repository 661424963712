import { Table, Tbody, TBox, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import warningIcon from '@/delivery/assets/icons/warning.svg';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { useCorporateProfileContext } from '@/delivery/ui/corporate/profile/context/corporate_profile_context.tsx';
import { useEffect, useState } from 'react';
import { getProfile } from '@/infrastructure/helper/auth.ts';
import DialogAddCorporate from '@/delivery/ui/corporate/profile/dialog/dialog_add_corporate_verification.tsx';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import { REFERRAL_LINK } from '@/domain/constant/referral/referral_link.ts';
import Line from '@/delivery/components/atoms/line/line.tsx';
import Icon from '@/domain/constant/icon.tsx';
import { STATUS_CORPORATE } from '@/domain/constant/status_corporate.ts';
import Loading from '@/delivery/components/atoms/loading/loading.tsx';

const CorporateProfile = () => {
  const alertToast = useAlertToast();
  const prof = getProfile();
  const {
    profileAffiliate,
    corporate,
    corporateVerify,
    handleShowCorporateVerification,
    handleShowStoreCorporateModal,
    handleShowCorporate,
    handleShowProfileAffiliate
  } = useCorporateProfileContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleSetStart();
  }, []);

  const handleSetStart = async () => {
    const x = alertToast.loadingAlert('Checking');
    setLoading(true);
    try {
      await handleShowCorporate({ email: prof?.email, user_uid: prof?.user_uuid });
      await handleShowCorporateVerification({ email: prof?.email, user_uid: prof?.user_uuid });
      await handleShowProfileAffiliate({ email: prof?.email });

      alertToast.updateLoading(x);
      setLoading(false);
    } catch (e: any) {
      alertToast.errorAlert(e);
      setLoading(false);
    }
  };

  return (
    <div>
      {/*UNFERIFIED CORPORATE*/}

      {loading && (
        <div className={'w-full'}>
          <Loading type={'linear'} />
        </div>
      )}

      {!corporateVerify && !loading && (
        <div className={' flex mb-[20px] justify-center'}>
          <div
            className={
              'w-3/4 bg-content-bg-white shadow-lg rounded-[12px] p-[35px] flex flex-col gap-[10px] justify-center items-center'
            }
          >
            <Image url={warningIcon} width={70} />
            <Typography fontWeight={600} size={20}>
              Akun Bisnis Anda Belum Aktif
            </Typography>
            <Typography fontWeight={400} size={16} type={'tertiary-invert'}>
              Lakukan verifikasi untuk mengaktifkan akun bisnis Anda.
            </Typography>
            <div className={'mt-[20px]'}>
              <Button
                label={'Verifikasi Sekarang'}
                prefixIcon={<IoMdCheckmarkCircleOutline />}
                onClick={() => handleShowStoreCorporateModal.setState(true)}
              />
            </div>
          </div>
        </div>
      )}

      {/*VERIFIED CORPORATE*/}
      {corporateVerify && !loading && (
        <div>
          {/*DATA VERIFICATION*/}
          <div>
            <Theader>
              <Typography size={14} fontWeight={600}>
                Status Verifikasi
              </Typography>
            </Theader>
            <TBox>
              <Table noBorder className="rounded-b-[8px]">
                <Thead>
                  <Tr>
                    <Th>Status</Th>
                    <Th>Note</Th>
                    <Th>Nilai Komisi</Th>
                    {/*<Th>Sales PIC</Th>*/}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Chips label={corporateVerify?.status} type={corporate?.getStatusColor} />
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.notes}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        -
                      </Typography>
                    </Td>

                    {/*<Td>*/}
                    {/*  <Typography fontWeight={400} size={12} type="primary-invert">*/}
                    {/*    -*/}
                    {/*  </Typography>*/}
                    {/*</Td>*/}
                  </Tr>
                </Tbody>
              </Table>
            </TBox>
          </div>

          {/*DATA REFERRAL*/}
          <div className="mt-[32px]">
            <Theader>
              <div>
                <Typography size={18} fontWeight={600}>
                  Data Referral
                </Typography>
              </div>
            </Theader>
            <TBox>
              <Table noBorder className="rounded-b-[8px]">
                <Thead>
                  <Tr>
                    <Th>Token Referral</Th>
                    <Th>Link Referral</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {profileAffiliate ? (
                    <Tr>
                      <Td>
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          {profileAffiliate.token}
                        </Typography>
                      </Td>
                      <Td>
                        <Typography fontWeight={400} size={12} type="primary-invert">
                          {`${REFERRAL_LINK}/${profileAffiliate.token}`}
                        </Typography>
                      </Td>
                    </Tr>
                  ) : (
                    <Tr>
                      <Td colSpan={4}>
                        <Typography size={12} fontWeight={500} style={'italic'}>
                          belum memiliki referral
                        </Typography>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TBox>
          </div>

          <div className={'my-[20px]'}>
            <Line />
          </div>
          {corporateVerify.status === STATUS_CORPORATE.REJECT && (
            <div className={'my-[20px] justify-end flex'}>
              <Button
                prefixIcon={Icon.pen}
                type={'secondary'}
                label={'Edit Data'}
                onClick={() => handleShowStoreCorporateModal.setState(true)}
              />
            </div>
          )}

          {/*DATA BUSINESS*/}
          <div className={'mt-[20px]'}>
            <Theader>
              <Typography size={14} fontWeight={600}>
                Data Bisnis
              </Typography>
            </Theader>
            <TBox>
              <Table noBorder className="rounded-b-[8px]">
                <Thead>
                  <Tr>
                    <Th>Nama Bisnis</Th>
                    <Th>Website</Th>
                    <Th>Alamat</Th>
                    <Th>NIB</Th>
                    <Th>Akta</Th>
                    <Th>NPWP</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.name}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.website}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.fullAddress}
                      </Typography>
                    </Td>
                    <Td>
                      <Image url={corporateVerify?.getCompanyRegistrationNumberUrl} width={200} />
                    </Td>
                    <Td>
                      <Image url={corporateVerify?.getCompanyDeedMediaUrl} width={200} />
                    </Td>
                    <Td>
                      <Image url={corporateVerify?.getTaxpayerIdentificationNumberUrl} width={200} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TBox>
          </div>

          {/*PIC*/}
          <div className={'mt-[20px]'}>
            <Theader>
              <Typography size={14} fontWeight={600}>
                Penanggung Jawab
              </Typography>
            </Theader>
            <TBox>
              <Table noBorder className="rounded-b-[8px]">
                <Thead>
                  <Tr>
                    <Th>Nama Lengkap</Th>
                    <Th>Email</Th>
                    <Th>Phone</Th>
                    <Th>KTP</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporate?.picName}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {prof?.email}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {prof?.phone}
                      </Typography>
                    </Td>
                    <Td>
                      <Image url={corporateVerify?.getIdentityUrl} width={200} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TBox>
          </div>

          {/*DATA BANK*/}
          <div className={'mt-[20px]'}>
            <Theader>
              <Typography size={14} fontWeight={600}>
                Data Bank
              </Typography>
            </Theader>
            <TBox>
              <Table noBorder className="rounded-b-[8px]">
                <Thead>
                  <Tr>
                    <Th>Nama Bank</Th>
                    <Th>Nama Rekening</Th>
                    <Th>Nomor Rekening</Th>
                    <Th>Buku Tabungan</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.masterBank.name}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.accountHolderName}
                      </Typography>
                    </Td>
                    <Td>
                      <Typography fontWeight={400} size={12} type="primary-invert">
                        {corporateVerify?.accountNumber}
                      </Typography>
                    </Td>
                    <Td>
                      <Image url={corporateVerify?.getCheckingAccountUrl} width={200} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TBox>
          </div>
        </div>
      )}

      {handleShowStoreCorporateModal.state && <DialogAddCorporate modal={handleShowStoreCorporateModal} />}
    </div>
  );
};

export default CorporateProfile;
