import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
import { CorporateRepository } from '@/domain/repository/corporate_repository.ts';
import { CorporateModel } from '@/domain/model/corporate_model.ts';
import { TransactionModel } from '@/domain/model/transaction_model.ts';
import { CorporateVerifyRepository } from '@/domain/repository/corporate_verify_repository.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface CorporateDetailValueParams {
  uid?: string;
  page?: number;
  limit?: number;
  user_uid?: string;
}

interface FormCorporateDetailInterface {
  uid?: string;
  sell?: number;
  status?: string;
}

interface CorporateDetailValue {
  myTransaction?: TransactionModel;
  myTransactionAffiliate?: TransactionModel;
  corporate?: CorporateModel;
  corporateVerify?: CorporateModel;
  formCorporateDetail: FormCorporateDetailInterface;
  setFormCorporateDetail: Dispatch<SetStateAction<FormCorporateDetailInterface>>;
  corporateDetailPagination?: PaginationModel;
  handleAddCorporateDetailModal: ModalObject;
  handleUpdateCorporateDetailModal: ModalObject;
  handleShowCorporate: (_data: CorporateDetailValueParams) => Promise<void>;
  handleShowCorporateVerify: (_data: CorporateDetailValueParams) => Promise<void>;
  handleFetchMyTransaction: (_data: CorporateDetailValueParams) => void;
  handleFetchMyAffiliate: (_data: CorporateDetailValueParams) => void;
}

const CorporateDetailContext = createContext<CorporateDetailValue | null>(null);

const corporateRep = new CorporateRepository();
const corporateVerifyRep = new CorporateVerifyRepository();

const CorporateDetailProvider: React.FC<DialogContextProps> = ({ children }) => {
  const alertToast = useAlertToast();

  const handleAddCorporateDetailModal = useVisibleComponent(false);
  const handleUpdateCorporateDetailModal = useVisibleComponent(false);

  // const [corporateDetail, setCorporateDetail] = useState<CorporateDetailModel>();
  const [corporate, setCorporate] = useState<CorporateModel>();
  const [corporateVerify, setCorporateVerify] = useState<CorporateModel>();
  const [myTransaction, setMyTransaction] = useState();
  const [myTransactionAffiliate, setMyTransactionAffiliate] = useState();
  // const [corporateDetailPagination, setCorporateDetailPagination] = useState<PaginationModel>();

  const [formCorporateDetail, setFormCorporateDetail] = useState<FormCorporateDetailInterface>({});

  //SHOW CORPORATE
  const handleShowCorporate = async (_data: CorporateDetailValueParams) => {
    const params = {
      uid: _data.uid
    };

    return corporateRep
      .showCorporate(params)
      .then((result: any) => {
        setCorporate(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //SHOW CORPORATE VERIFY
  const handleShowCorporateVerify = async (_data: CorporateDetailValueParams) => {
    const params = {
      user_uid: _data.user_uid
    };

    return corporateVerifyRep
      .showCorporateVerify(params)
      .then((result: any) => {
        setCorporateVerify(result);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //SHOW MY TRANSACTION
  const handleFetchMyTransaction = (_data: CorporateDetailValueParams) => {
    const x = alertToast.loadingAlert('');

    corporateRep
      .fetchCorporateMyTransactions({})
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyTransaction(result);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //SHOW MY AFFILIATE
  const handleFetchMyAffiliate = (_data: CorporateDetailValueParams) => {
    const x = alertToast.loadingAlert('');

    corporateRep
      .fetchCorporateMyAffiliates({})
      .then((result: any) => {
        alertToast.updateLoading(x);
        setMyTransactionAffiliate(result);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: CorporateDetailValue = {
    myTransaction,
    myTransactionAffiliate,
    corporate,
    corporateVerify,
    formCorporateDetail,
    setFormCorporateDetail,
    handleShowCorporate,
    handleShowCorporateVerify,
    handleAddCorporateDetailModal,
    handleUpdateCorporateDetailModal,
    handleFetchMyTransaction,
    handleFetchMyAffiliate
  };

  return <CorporateDetailContext.Provider value={contextValue}>{children}</CorporateDetailContext.Provider>;
};

const useCorporateDetailContext = (): CorporateDetailValue => {
  const context = useContext(CorporateDetailContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a CorporateDetailProvider');
  }
  return context;
};

export { CorporateDetailProvider, useCorporateDetailContext };
