import { useCorporateDetailContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_detail_context.tsx';
import { Table, Tbody, TBox, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import TableBusinessInfo from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/detail/table_business_info.tsx';
import TableBankAccount from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/detail/table_bank_account.tsx';
import TableDocument from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/detail/table_document.tsx';
import TableBusinessAddress from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/detail/table_business_address.tsx';
import TableMailAddress from '@/delivery/ui/admin/data_corporate/corporate_list/detail/tab/detail/table_mail_address.tsx';

const DetailCorporate = () => {
  const { corporate } = useCorporateDetailContext();

  return (
    <div>
      <div>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Data Bisnis
            </Typography>
          </div>
        </Theader>
        <Table noBorder className="rounded-b-[8px]">
          <Thead>
            <Tr>
              <Th>Member</Th>
              <Th>Penangung Jawab</Th>
              <Th>Bank Account</Th>
              <Th>Request Date</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <Typography fontWeight={600} size={12} type="primary-invert">
                  {corporate?.name}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {corporate?.emailCorporate}
                </Typography>
                <Typography fontWeight={400} size={12} type="tertiary-invert">
                  {corporate?.phoneCorporate}
                </Typography>
              </Td>
              <Td>
                <Typography fontWeight={600} size={12} type="primary-invert">
                  {corporate?.picName}
                </Typography>
              </Td>
              <Td>
                <div className=" text-[12px] font-[400] text-text-tertiary-invert whitespace-nowrap">
                  <Typography fontWeight={600} size={12} type="primary-invert">
                    {corporate?.masterBank?.name}
                  </Typography>
                  <p>
                    Acc Name:{' '}
                    <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                      {corporate?.accountHolderName}
                    </Typography>
                  </p>
                  <p>
                    Acc No:{' '}
                    <Typography tag="span" fontWeight={600} size={12} type="secondary-invert">
                      {corporate?.accountNumber}
                    </Typography>
                  </p>
                </div>
              </Td>
              <Td>
                <Typography tag="span" fontWeight={400} size={12} type="tertiary-invert">
                  {corporate?.getCreatedAt}
                </Typography>
              </Td>
              <Td>
                <div className={'whitespace-nowrap'}>
                  <Chips type={corporate?.getStatusColor} label={corporate?.status} className="leading-4" />
                  <Typography className="leading-4" fontWeight={400} size={10} type="tertiary-invert"></Typography>
                </div>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <div className={'mt-[20px]'}>
        <Theader>
          <div>
            <Typography size={14} fontWeight={600}>
              Bisnis Detail
            </Typography>
          </div>
        </Theader>
        <TBox>
          <div className={'grid grid-cols-2 gap-[20px]'}>
            <div className={'flex flex-col gap-[20px]'}>
              <TableBusinessInfo />
              <TableBankAccount />
              <TableDocument />
            </div>
            <div className={'flex flex-col gap-[20px]'}>
              <TableBusinessAddress />
              <TableMailAddress />
            </div>
          </div>
        </TBox>
      </div>
    </div>
  );
};

export default DetailCorporate;
