import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import { AuthRepository } from '@/domain/repository/auth_repository.ts';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';
import { useNavigate } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import { COMPANY_UUID } from '@/domain/constant/company/company_uuid.ts';

interface DialogContextProps {
  children: ReactNode;
}

// interface RegistrationCorporateUserValueParams {
//   page?: number;
//   limit?: number;
// }

interface FormRegistrationCorporateUser {
  company_uuid?: string[];
  default_company_uuid?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  phone?: string;
  role_uuid?: string;
  type?: string;
  username?: string;
  verified_status?: boolean;
  company_name?: string;
}

interface RegistrationCorporateUserValue {
  //INTERFACE
  formRegistrationCorporateUser: FormRegistrationCorporateUser;
  setFormRegistrationCorporateUser: Dispatch<SetStateAction<FormRegistrationCorporateUser>>;
  handleStoreCorporateUser: () => void;
}

const RegistrationCorporateUserContext = createContext<RegistrationCorporateUserValue | null>(null);

//REPOSITORY HERE
const authRep = new AuthRepository();

const RegistrationCorporateUserProvider: React.FC<DialogContextProps> = ({ children }) => {
  const navigate = useNavigate();
  const alertToast = useAlertToast();
  const alertSweet = useAlertSweetComponent();

  //const handleModal = useVisibleComponent(false);
  const [formRegistrationCorporateUser, setFormRegistrationCorporateUser] = useState<FormRegistrationCorporateUser>({});

  //FUNCTION HERE BELOW
  const handleStoreCorporateUser = () => {
    alertSweet.questionAlert('Apakah anda yakin data sudah benar?').then((result: { isConfirmed: any }) => {
      if (result.isConfirmed) {
        const x = alertToast.loadingAlert('');
        const params = {
          first_name: formRegistrationCorporateUser.first_name,
          last_name: formRegistrationCorporateUser.last_name,
          password: formRegistrationCorporateUser.password,
          phone: formRegistrationCorporateUser.phone,
          company_uuid: [`${COMPANY_UUID}`],
          default_company_uuid: COMPANY_UUID,
          email: formRegistrationCorporateUser.email,
          role_uuid: [''],
          type: MEMBER_TYPE.CORPORATE,
          username: formRegistrationCorporateUser.username,
          verified_status: true,
          company_name: formRegistrationCorporateUser.company_name
        };

        authRep
          .register(params)
          .then(() => {
            alertToast.updateLoading(x);
            alertToast.successAlert('Register sukses silahkan melakukan login');
            setTimeout(() => {
              navigate(Routing.LOGIN);
            }, 1000);
          })
          .catch(error => {
            alertToast.updateLoading(x);
            alertToast.errorAlert(error);
          });
      }
    });
  };

  const contextValue: RegistrationCorporateUserValue = {
    //INTERFACE
    formRegistrationCorporateUser,
    setFormRegistrationCorporateUser,
    handleStoreCorporateUser
  };

  return (
    <RegistrationCorporateUserContext.Provider value={contextValue}>
      {children}
    </RegistrationCorporateUserContext.Provider>
  );
};

const useRegistrationCorporateUserContext = (): RegistrationCorporateUserValue => {
  const context = useContext(RegistrationCorporateUserContext);
  if (!context) {
    throw new Error('useRegistrationCorporateUserContext must be used within a RegistrationCorporateUserProvider');
  }
  return context;
};

export { RegistrationCorporateUserProvider, useRegistrationCorporateUserContext };
