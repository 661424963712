import { Routing } from '@/domain/constant/router_path.ts';
import { FaArrowLeft } from 'react-icons/fa6';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';

import { useNavigate } from 'react-router-dom';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import { TBox } from '@/delivery/components/molecules/table/table.tsx';
import Line from '@/delivery/components/atoms/line/line.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import { useWebPageContext } from '@/delivery/ui/admin/web_admin/web_pages/context/web_page_context.tsx';
import TagEditor from '@/delivery/components/atoms/text_editor/tag_editor.tsx';

const CreateWebPage = () => {
  const navigate = useNavigate();
  const { formWebPage, setFormWebPage, handleStoreDataWebPage } = useWebPageContext();

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.WEB_ADMIN.WEB_PAGES)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to web page
        </Typography>
      </div>

      <div>
        <Typography size={24} fontWeight={600}>
          Add New Web Page
        </Typography>
      </div>
      <Line />
      <TBox className={'flex flex-col gap-[20px]'}>
        <div className="flex flex-col gap-[20px] w-full">
          <div className={' flex gap-[20px]'}>
            <TextField
              block
              label={'Title'}
              onChange={e => setFormWebPage({ ...formWebPage, title: e.target.value })}
            />
            <TextField block label={'Slug'} onChange={e => setFormWebPage({ ...formWebPage, slug: e.target.value })} />
          </div>
          <TagEditor
            onChange={(e: any) => {
              setFormWebPage({ ...formWebPage, content: e });
            }}
            value={formWebPage.content}
            height={'90vh'}
          />
        </div>
        <Line />
        <div className={'flex gap-[20px] justify-end'}>
          <Button type={'secondary'} label={'Cancel'} onClick={() => navigate(Routing.WEB_ADMIN.WEB_PAGES)} />
          <Button label={'Save'} onClick={() => handleStoreDataWebPage()} />
        </div>
      </TBox>
    </div>
  );
};

export default CreateWebPage;
