import { Table, Tbody, Td, Th, Thead, Tr } from '@/delivery/components/molecules/table/table.tsx';
import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { Image } from '@/delivery/components/atoms/image/image.tsx';
import { useCorporateDetailContext } from '@/delivery/ui/admin/data_corporate/corporate_list/context/corporate_detail_context.tsx';

const TableBankAccount = () => {
  const { corporateVerify } = useCorporateDetailContext();
  return (
    <Table noBorder className="rounded-b-[8px]">
      <Thead>
        <Tr noBorder>
          <Th noBorder>
            <Typography fontWeight={600} size={14} type="primary-invert">
              Akun Bank
            </Typography>
          </Th>
          <Th noBorder></Th>
        </Tr>
      </Thead>
      <Tbody>
        {/*bank name*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Bank Name
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporateVerify?.masterBank?.name}
            </Typography>
          </Td>
        </Tr>

        {/*bank acc no*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Bank Acc No
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporateVerify?.accountNumber}
            </Typography>
          </Td>
        </Tr>

        {/*Bank acc holder*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Bank Acc Holder
            </Typography>
          </Td>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="primary-invert">
              : {corporateVerify?.accountHolderName}
            </Typography>
          </Td>
        </Tr>

        {/*buku tabungan*/}
        <Tr>
          <Td noBorder>
            <Typography fontWeight={400} size={14} type="tertiary-invert">
              Buku Tabungan
            </Typography>
          </Td>
          <Td noBorder>
            <div className={'flex items-center gap-[20px] pr-[20px]'}>
              <Typography fontWeight={400} size={14} type="primary-invert">
                :
              </Typography>
              <Image url={corporateVerify?.getCheckingAccountUrl} />
            </div>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default TableBankAccount;
